export type ClickEvent =
  | 'BannerInstallAppClick'
  | 'HomeSearchClick'
  | 'HomeTopBannerClick'
  | 'HomeCreateBosyuClick'
  | 'HomeCreateProfileCardClick'
  | 'HomeJoinDiscordClick'
  | 'HomeSearchUserClick'
  | 'HomeUserProfileClick'
  | 'HomePickedItemClick'
  | 'HomeReadPostClick'
  | 'HomeContentsClick'
  | 'BosyuCreateClick'
  | 'BosyuStopClick'
  | 'BosyuDetailStopClick'
  | 'BosyuDetailJoinClick'
  | 'ProfileCardEditorCreateClick'
  | 'ProfileCardDetailCreateProfileCardClick'
  | 'ProfileCardDetailOpenTwitterProfileClick'
  | 'ProfileCardDetailOpenProfileClick'
  | 'ProfileCardDetailInstallBetaClick'
  | 'MyProfileCreateSelectGameTitleClick'
  | 'MyProfileCreateDetailCreateClick'
  | 'MyProfileDetailShareClick'
  | 'MyProfileDetailAppInstallClick'
  | 'MyProfileInstallBetaClick'
  | 'MyProfileDetailCopyUrlClick'
  | 'SearchResultUserClick'
  | 'SearchUserClick'
  | 'AdvancedSearchClick'
  | 'HeaderLogoutClick'
  | 'HeaderLoginClick'
  | 'HeaderSignUpClick'
  | 'NavCreateClick'
  | 'NavSearchClick'
  | 'NavProfileClick'
  | 'NavSelectGameTitleClick'
  | 'NavPostsClick'
  | 'NavDiscoveryClick'
  | 'PlayClipLinkClick'
  | 'ReadPostClick'
  | 'ModalAppInstallClick'
  | 'LoginTwitterClick'
  | 'LoginGoogleClick'
  | 'LoginAppleClick'
  | 'FooterAppInstallClick'
  | 'ShindanDetailCopyUrlClick'
  | 'ShindanDetailShareClick'
  | 'UserDetailSendMessageClick'

export const clickEvents: Record<ClickEvent, string> = {
  BannerInstallAppClick: 'banner_install_app_click',
  HomeSearchClick: 'home_search_click',
  HomeTopBannerClick: 'home_top_banner_click',
  HomeCreateBosyuClick: 'home_create_bosyu_click',
  HomeCreateProfileCardClick: 'home_create_profile_card_click',
  HomeJoinDiscordClick: 'home_join_discord_click',
  HomeSearchUserClick: 'home_search_user_click',
  HomeUserProfileClick: 'home_user_profile_click',
  HomePickedItemClick: 'home_picked_item_click',
  HomeContentsClick: 'home_contents_click',
  HomeReadPostClick: 'home_read_post_click',
  BosyuCreateClick: 'bosyu_create_click',
  BosyuStopClick: 'bosyu_stop_click',
  BosyuDetailStopClick: 'bosyu_detail_stop_click',
  BosyuDetailJoinClick: 'bosyu_detail_join_click',
  ProfileCardEditorCreateClick: 'profile_card_editor_create_click',
  ProfileCardDetailCreateProfileCardClick:
    'profile_card_detail_create_profile_card_click',
  ProfileCardDetailOpenTwitterProfileClick:
    'profile_card_detail_open_twitter_profile_click',
  ProfileCardDetailOpenProfileClick: 'profile_card_detail_open_profile_click',
  ProfileCardDetailInstallBetaClick: 'profile_card_detail_install_beta_click',
  MyProfileCreateSelectGameTitleClick: 'my_page_profiles_decide_template_click',
  MyProfileCreateDetailCreateClick:
    'my_page_profiles_create_detail_create_click',
  MyProfileDetailShareClick: 'my_profile_detail_share_click',
  MyProfileDetailAppInstallClick: 'my_profile_detail_app_install_click',
  MyProfileInstallBetaClick: 'my_profile_install_beta_click',
  MyProfileDetailCopyUrlClick: 'my_profile_detail_copy_url_click',
  SearchResultUserClick: 'search_result_user_click',
  SearchUserClick: 'search_user_click',
  AdvancedSearchClick: 'advanced_search_click',
  HeaderLogoutClick: 'header_logout_click',
  HeaderLoginClick: 'header_login_click',
  HeaderSignUpClick: 'header_sign_up_click',
  NavCreateClick: 'nav_create_click',
  NavSearchClick: 'nav_search_click',
  NavProfileClick: 'nav_profile_click',
  NavSelectGameTitleClick: 'nav_decide_title_click',
  NavPostsClick: 'nav_posts_click',
  NavDiscoveryClick: 'nav_discovery_click',
  PlayClipLinkClick: 'play_clip_link_click',
  ReadPostClick: 'read_post_click',
  ModalAppInstallClick: 'modal_app_install_click',
  LoginTwitterClick: 'login_twitter_click',
  LoginGoogleClick: 'login_google_click',
  LoginAppleClick: 'login_apple_click',
  FooterAppInstallClick: 'footer_app_install_click',
  ShindanDetailCopyUrlClick: 'shindan_detail_copy_url_click',
  ShindanDetailShareClick: 'shindan_detail_share_click',
  UserDetailSendMessageClick: 'user_detail_send_message_click',
} as const

export type ViewEvent =
  | 'HomeView'
  | 'BosyuView'
  | 'RoomView'
  | 'BosyuDetailView'
  | 'LoginOrSignUpView'
  | '404View'
  | 'SearchResultView'
  | 'UsersDetailView'
  | 'ProfilesDetailView'
  | 'MyPageView'
  | 'MyProfileDetailView'
  | 'MyProfileCreateSelectGameTitleView'
  | 'MyProfileCreateView'
  | 'MyPageEditView'
  | 'MyPageEditDetailView'
  | 'PromotesView'
  | 'ContentsView'
  | 'ContentsTopicsView'
  | 'DiscoveryView'
  | 'GroupRoomView'
  | 'GroupRoomDetailView'
  | 'ShindanView'
  | 'AnnouncesView'
  | 'FriendRequestsView'

export const viewEvents: Record<ViewEvent, string> = {
  HomeView: 'home_view',
  BosyuView: 'bosyu_view',
  RoomView: 'room_view',
  BosyuDetailView: 'bosyu_detail_view',
  LoginOrSignUpView: 'login_or_sign_up_view',
  '404View': '404_view',
  SearchResultView: 'search_result_view',
  UsersDetailView: 'users_detail_view',
  ProfilesDetailView: 'profiles_detail_view',
  MyPageView: 'my_page_view',
  MyProfileDetailView: 'my_profile_detail_view',
  MyProfileCreateSelectGameTitleView:
    'my_profile_create_select_game_title_view',
  MyProfileCreateView: 'my_profile_create_view',
  MyPageEditView: 'my_page_edit_view',
  MyPageEditDetailView: 'my_page_edit_detail_view',
  PromotesView: 'promotes_view',
  ContentsView: 'contents_view',
  ContentsTopicsView: 'contents_topics_view',
  DiscoveryView: 'discovery_view',
  GroupRoomView: 'group_room_view',
  GroupRoomDetailView: 'group_room_detail_view',
  ShindanView: 'shindan_view',
  AnnouncesView: 'announces_view',
  FriendRequestsView: 'friend_requests_view',
} as const
